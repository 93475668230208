//import { createRouter, createWebHashHistory } from 'vue-router'
import { createApp } from 'vue'

import Index from './index.vue'

/*
const routes = [
    { path: '/', name:'Root', component: Index },
    { path: '/game', name:'Game', component: Game },
]

const router = createRouter({
    history: true,//createWebHashHistory(),
    routes: routes,
})
*/
const app = createApp(Index)
//app.use(VueNextSelect);
//app.use(router)
app.mount('#main')
