<template>
  <!--img alt="Vue logo" src="./assets/logo.png"-->
  <div>
    <NavBar v-bind:items="[{name:'Home'}, {name:'Features'}, {name:'About'}]"/>

    <div class="container">
      <div class="row align-items-center mt-4">
        <img alt="Narco Dons House" src="../assets/images/house.jpg">
      </div>
      <div class="row align-items-center mt-4">
        <img alt="Narco Dons Yacht" src="../assets/images/yacht.jpg">
      </div>
      <div class="row align-items-center mt-4">
        <img alt="Narco Dons Cars" src="../assets/images/cars.jpg">
      </div>
    </div>

  </div>
</template>

<style>
/*@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');*/
#main {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
.TitleFont {
    font-family: 'Bebas Neue', cursive;
    font-size: 64px;
}
.btn-outline-login {

}
</style>

<script>
//import * as Common from '../modules/common.js'
import NavBar from '../components/navbar.vue'

export default {
  //runtimeCompiler: true,
  name: 'Index',
  components: {
    NavBar,
  },
  created() {
    //var token = Common.GetToken();
    //console.log("1", token)
    //var t = Common.DecodeToken(token);
    //console.log("1", t)
    //this.changeComponentState(t.status);
  },
  methods: {
    changeSetting: function(index) {
      //data.settingsSelected = data.settings[index];
    },
    swapComponent: function(component) {
      console.log(component)
      this.RegisterComponent = component;
    },
    changeComponentState(state) {
      console.log(state);
      if (state == "GAME")
          document.location.href = "/game/";
      else
          document.location.href = "/signup/";
    },
  },
  data() {
    return {
      RegisterComponent: `Signup`
    };
  }     
}
</script>
